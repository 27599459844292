<template>
	<div>
		<canvas :id="`${chartId}`" width="574" height="340"></canvas>
	</div>
</template>

<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
	name: 'Chart',

	props: {
		chartId: {
			type: String,
			required: true
		},
		addOptions: {
			type: Object,
			default: () => ({})
		},
		data: {
			type: Object,
			default: () => ({})
			/*
				Example
				{
					labels: ['Satu', 'Dua', 'Tiga', 'Empat', 'Lima'],
					values: [12, 19, 3, 5, 3],
				}
			*/
		},
		type: {
			type: String,
			default: 'bar'
		}
	},

	computed: {
		chartData() {
			return {
				labels: this.data.labels,
				datasets: [
					{
						data: this.data.values,
						backgroundColor: '#A4237E',
					},
				],
			}
		},
		chartOptions() {
			return {
				layout: {
					padding: {
						top: 32,
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						enabled: false,
					},
					datalabels: {
						anchor: 'end',
						align: 'top',
						offset: 5,
						color: '#303030',
						font: {
							weight: 'bold',
						},
					},
				},
				scales: {
					x: {
						grid: {
							display: false,
						},
						ticks: {
							font: {
								weight: 'bold',
							},
						}
					},
					y: {
						grid: {
							display: false,
							drawBorder: false,
						},
						beginAtZero: true,
						ticks: {
							precision: 0,
							font: {
								weight: 'bold',
							},
						}
					},
				},
				...this.addOptions
			}
		}
	},

	watch: {
		data: {
			deep: true,
			handler() {
				this.updateChartElement();
			}
		}
	},

  mounted() {
    this.createChartElement();
  },

	methods: {
    createChartElement() {
      const ctx = document.getElementById(`${this.chartId}`).getContext('2d');
      this.myChart = new Chart(ctx, {
				type: this.type,
				plugins: [ChartDataLabels],
				data: this.chartData,
				options: this.chartOptions
			});
    },
		updateChartElement() {
      if (this.myChart) {
        this.myChart.data = this.chartData;
        this.myChart.options = this.chartOptions;
        this.myChart.update();
      }
    },
  },
}
</script>